import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import "@solana/wallet-adapter-react-ui/styles.css"
import Home from './Home';
import { useWallet } from '@solana/wallet-adapter-react';

function App() {
  const { publicKey } = useWallet()
  return (
    <div>
      {!publicKey &&
        <div className='h-screen w-screen flex justify-center items-center bg-gradient-to-r from-blue-100 to-blue-500'>
          <div className='h-screen w-screen flex justify-center items-center'>
            <WalletMultiButton ></WalletMultiButton>
          </div>
        </div>
      }

      {publicKey &&
      <div>
        <div className='w-screen flex justify-end p-10 bg-blue-100'>
          <div className='w-screen flex justify-end'>
            <WalletMultiButton ></WalletMultiButton>
            </div>
            </div>
        <Home></Home>
        </div>
        }
          </div>
          );
}

          export default App;
