import { User } from './User';
import { useEffect, useState } from 'react';

const Home = () => {
    const { initialized, initializeUser, publicKey, activateText, setTextAndTime, getAccount, getAllAccounts } = User();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [time, setTextTime] = useState('');
    const [state, setState] = useState();
    const [userTime, setUserTime] = useState();

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const account = await getAccount();
                const allAccount = await getAllAccounts();
                setState(account.state);
                setUserTime(account.time);
                setPhoneNumber(account.phone);
                setTextTime(account.time);
            } catch (error) {
                console.error('Error fetching account:', error);
            }
        };

        fetchAccount();
    }, [initialized]);

    const handlePhoneNumberChange = (e) => {
        let formattedPhoneNumber = e.target.value.replace(/\D/g, '');
        if (formattedPhoneNumber.length > 3) {
            formattedPhoneNumber = `${formattedPhoneNumber.slice(0, 3)}-${formattedPhoneNumber.slice(3)}`;
        }
        if (formattedPhoneNumber.length > 7) {
            formattedPhoneNumber = `${formattedPhoneNumber.slice(0, 7)}-${formattedPhoneNumber.slice(7)}`;
        }
        if (formattedPhoneNumber.length > 12) {
            formattedPhoneNumber = `${formattedPhoneNumber.slice(0, 12)}`;
        }
        setPhoneNumber(formattedPhoneNumber);
    };

    const handleTimeChange = (e) => {
        setTextTime(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await setTextAndTime(phoneNumber, time).then(await activateText(1));
        if (success) {
            try {

                const response = await fetch(`https://good-morning-solana-90f0964710f4.herokuapp.com/getUser/${publicKey.toString()}/${phoneNumber}/${time}`);
                const data = await response.json();
                const account = await getAccount();
                setState(account.state);
                setUserTime(account.time);
                setPhoneNumber(account.phone);
                setTextTime(account.time);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const cancelText = async (e) => {
        e.preventDefault();
        const success = await activateText(0);
        if (success) {
            try {
                const response = await fetch(`https://good-morning-solana-90f0964710f4.herokuapp.com/deleteUser/${publicKey.toString()}`);
                const data = await response.json();
            } catch (error) {
                console.error(error);
            }
            setState(0);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-200 p-10">
            {!initialized && (
                <div className="flex justify-center items-center h-full">
                    <button
                        className="px-8 py-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition-colors"
                        onClick={initializeUser}
                    >
                        Initialize
                    </button>
                </div>
            )}
            {initialized && (
                <div className="flex justify-between items-start mt-10">
                    <div className="bg-white p-10 rounded-lg shadow-md w-1/2">
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Wallet:
                                </label>
                                <p className="text-lg text-gray-900">{publicKey.toString()}</p>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700" htmlFor="phoneNumber">
                                    Phone Number:
                                </label>
                                <input
                                    type="tel"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    placeholder="123-456-7890"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700" htmlFor="time">
                                    Time:
                                </label>
                                <input
                                    type="time"
                                    id="time"
                                    name="time"
                                    value={time}
                                    onChange={handleTimeChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    required
                                />
                            </div>
                            <div className="flex space-x-3">
                                <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                    Submit
                                </button>
                                <button onClick={cancelText} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                    Cancel
                                </button>
                            </div>
                        </form>
                        {state === 1 && <h1 className="mt-4 text-green-600 font-bold">Task Scheduled for {userTime}</h1>}
                        {state === 0 && <h1 className="mt-4 text-red-600 font-bold">No Task Scheduled</h1>}
                    </div>
                    <div className="bg-gradient-to-b from-blue-200 to-blue-300 p-10 rounded-lg shadow-md w-1/3 ml-10">
                        <h1 className="text-3xl font-bold text-purple-700 text-center mb-6">Good Morning Solana</h1>
                        <p className="text-lg text-purple-900 leading-relaxed">
                            "Good Morning Solana" is a refreshing application that brings a delightful start to your day. Imagine waking up to a friendly morning text, but with a twist – it contains the current value of your digital assets on the Solana blockchain. No more scrolling through multiple apps or websites to check your token values; it's all conveniently delivered right to your phone each morning.
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
