import * as anchor from '@project-serum/anchor'
import { useEffect, useMemo, useState } from 'react'
import { GMS_PROGRAM_PUBKEY } from "./constants/index"
import gmsIDL from './constants/gms.json'
import toast from 'react-hot-toast'
import { SystemProgram, Transaction } from '@solana/web3.js'
import { utf8 } from '@project-serum/anchor/dist/cjs/utils/bytes'
import { findProgramAddressSync } from '@project-serum/anchor/dist/cjs/utils/pubkey'
import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react'



export function User() {
    const { connection } = useConnection()
    const { publicKey, sendTransaction,signAllTransactions} = useWallet()
    const anchorWallet = useAnchorWallet()
    const [loading, setLoading] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [transactionPending, setTransactionPending] = useState(false)


    const program = useMemo(() => {
        if (anchorWallet) {
            const provider = new anchor.AnchorProvider(connection, anchorWallet, anchor.AnchorProvider.defaultOptions())
            return new anchor.Program(gmsIDL, GMS_PROGRAM_PUBKEY, provider)
        }
    }, [connection, anchorWallet])

    useEffect(() => {
        const findProfileAccounts = async () => {
            if (program && publicKey && !transactionPending) {
                try {
                    setLoading(true)
                    const [profilePda, profileBump] = await findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                    const profileAccount = await program.account.userProfile.fetch(profilePda)

                    if (profileAccount) {
                        setInitialized(true)
                    } else {
                        setInitialized(false)
                    }
                } catch (error) {
                    console.log(error)
                    setInitialized(false)
                } finally {
                    setLoading(false)
                }
            }
        }

        findProfileAccounts()
    }, [publicKey, program, transactionPending])
    const getAllAccounts = async () => {
        if (program && publicKey) {
            const profileAccount = await program.account.userProfile.all()
            if (profileAccount) {
                return profileAccount
            }
            else {
                return 0;
            }
        } else {
        }
    }

    const getCurrState = async () => {
        if (program && publicKey) {
            const [profilePda, profileBump] = await findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
            const profileAccount = await program.account.userProfile.fetch(profilePda)
            if (profileAccount) {
                return profileAccount.state
            }
            else {
                return 0;
            }
        } else {
        }
    }
    const getAccount = async () => {
        if (program && publicKey) {
            const [profilePda, profileBump] = await findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
            const profileAccount = await program.account.userProfile.fetch(profilePda)
            if (profileAccount) {
                return profileAccount
            }
            else {
                return 0;
            }
        } else {
        }
    }
  

    const activateText = async (change_state) => {
        if (program && publicKey) {
            try {
                setTransactionPending(true)
                setLoading(true)
                const [profilePda, profileBump] = findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                const tx = await program.methods.activateText(change_state).accounts({
                    authority: publicKey,
                    userProfile: profilePda,
                    systemProgram: SystemProgram.programId
                }).rpc()
            } catch {
                return false
            } finally {
                setTransactionPending(false)
                setLoading(false)
            }
        }
        return true
    }
    const setText = async (_change_number) => {
        if (program && publicKey) {
            try {
                setTransactionPending(true)
                setLoading(true)
                const [profilePda, profileBump] = findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                const tx = await program.methods.setText(_change_number).accounts({
                    authority: publicKey,
                    userProfile: profilePda,
                    systemProgram: SystemProgram.programId
                }).rpc()
            } catch {
                return false
            } finally {
                setTransactionPending(false)
                setLoading(false)
            }
        }
        return true
    }
    const setTime = async (time) => {
        if (program && publicKey) {
            try {
                setTransactionPending(true)
                setLoading(true)
                const [profilePda, profileBump] = findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                const tx = await program.methods.setTime(time).accounts({
                    authority: publicKey,
                    userProfile: profilePda,
                    systemProgram: SystemProgram.programId
                }).rpc()
            } catch {
                return false
            } finally {
                setTransactionPending(false)
                setLoading(false)
            }
        }
        return true
    }


    const initializeUser = async () => {
        if (program && publicKey) {
            try {
                setTransactionPending(true)
                const [profilePda, profileBump] = findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                const tx = await program.methods
                    .initUser("", "")
                    .accounts({
                        authority: publicKey,
                        userProfile: profilePda,
                        systemProgram: SystemProgram.programId,
                    }).rpc()
                setInitialized(true)
                toast.success('Successfully initialized user.')
            } catch (error) {
                console.log(error)
                toast.error(error.toString())
            } finally {
                setTransactionPending(false)
            }
        }
    }
    const setTextAndTime = async (_change_number, time) => {
        if (program && publicKey) {
            try {
                setTransactionPending(true)
                setLoading(true)

                const transaction = new Transaction().add(
                    SystemProgram.transfer({
                        fromPubkey: publicKey,
                        toPubkey: process.env.REACT_APP_RECIEVE_WALLET,
                        lamports: 1_000_000_0,
                    })
                );
                const signature = await sendTransaction(transaction, connection);

                if(signature) {
                    const [profilePda, profileBump] = findProgramAddressSync([utf8.encode('USER_STATE'), publicKey.toBuffer()], program.programId)
                    const tx = program.methods.setTextAndTime(time,_change_number).accounts({
                            authority: publicKey,
                            userProfile: profilePda,
                            systemProgram: SystemProgram.programId
                        }).rpc()
    
                    await tx
                }

               
                   
            } catch {
                return false
            } finally {
                setTransactionPending(false)
                setLoading(false)
            }
        }
        return true
    }
    return { initialized, initializeUser, activateText, getCurrState, getAccount, setTime, setText, setTextAndTime, publicKey,getAllAccounts }

}